<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h5>Cobranças de movimentação</h5>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col
        cols="12"
        xl="4"
        lg="4"
        md="4"
        sm="4"
        v-for="(card, index) in model.cards"
        :key="index"
      >
        <CardBilling
          :employeeId="employeeId"
          :amountLife="card.amountLives"
          :subtotal="card.subtotal"
          :productType="card.productType"
          :disabled="canEdit"
        />
      </v-col>
      <v-col cols="12" xl="12" lg="12" md="12" sm="12">
        <div class="b-row-total">
          <strong>Total: </strong>
          <strong>{{ formatMoney(model.total) }}</strong>
        </div>
      </v-col>
    </v-row>

    <v-divider class="my-5" />

    <v-row dense class="mt-4">
      <v-col class="text-right">
        <BaseButton @click="back" outlined color="primary" title="Voltar" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { formatMoney } from '@/helpers/formatting';
import { MovementService } from '@/services/api/movements';

export default {
  components: {
    CardBilling: () => import('@/components/cards/card-billing')
  },

  props: {
    customerId: {
      type: String,
      require: true
    },
    employeeId: {
      type: String,
      require: true
    }
  },

  data: () => ({
    model: {
      cards: [],
      total: 0
    }
  }),

  computed: {
    ...mapGetters({
      employee: 'movements/getEmployee'
    }),

    canEdit() {
      const situation =
        this.employee.situation === 'Ativo' ||
        this.employee.situation === 'Em análise na operadora';

      return situation;
    }
  },

  created() {
    this.search();
  },

  methods: {
    formatMoney,

    async search() {
      try {
        const movementService = new MovementService();
        const { status, data } = await movementService.getEmployeeBillingCards(
          this.employeeId
        );

        if (status === 200) {
          this.model = data;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    back() {
      this.$emit('back');
    }
  }
};
</script>
<style lang="scss">
.b-row-total {
  width: 100%;
  padding: 0.5rem 1rem;
  text-align: right;
  background-color: rgba($gray--20, 0.2);
  border-radius: 5px;

  strong {
    &:last-child {
      color: var(--v-success-base);
    }
  }
}
</style>
